@import '/styles/base';

// Primary nav width
$primary-lg-width: 20vw;
$primary-lg-width-expanded: 29vw;
$primary-lg-width-growth: $primary-lg-width-expanded - $primary-lg-width;

// Wordmark height
$wordmark-lg-height: 7.2rem;
$wordmark-lg-height-expanded: 8.8rem;
$wordmark-lg-height-growth: $wordmark-lg-height-expanded - $wordmark-lg-height;

// Wordmark spacing (top and bottom)
$wordmark-lg-spacing: 4rem;
$wordmark-lg-spacing-expanded: 5.2rem;
$wordmark-lg-spacing-growth: $wordmark-lg-spacing-expanded - $wordmark-lg-spacing;

$primary-sticky-mobile-height: 7.6rem;
$primary-sticky-tablet-height: 9.2rem;

$primary-mobile-height: 10.4rem;
$primary-tablet-height: 12.8rem;

$wordmark-logo-height: 6.4rem;

$wormark-logo-margin-bottom-lg: 4rem;
$primary-top-padding-lg: 4rem;
