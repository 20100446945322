@use '/styles/base.scss' as *;
@use './navigation.constants.module.scss' as navigation;

.navigationWrapper {
  @include md {
    height: navigation.$primary-sticky-tablet-height;
  }

  @include lg {
    position: absolute;
    height: auto;
  }

  pointer-events: none;

  position: sticky;
  z-index: $z-200;
  top: -0.1rem;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;

  height: navigation.$primary-sticky-mobile-height;
}
